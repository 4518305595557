import { API } from '@/common/api';
import { Project } from '@/common/models/project';
import { ChatUser } from '@/common/models/ChatUser';
import { Event } from '@/common/models/Event';
import { LogFilterModel } from '@/common/components/logs/models/log-filter-model';

interface UsersFilter {
    timestamp_from?: number;
    timestamp_to?: number;

    channel_ids?: number[];
    version_ids?: number[];

    search?: string;

    with_fails?: boolean;

    ascending?: boolean;

    offset?: number;
    page?: number;
    count?: number;
}

export interface LogChatPagination {
    ascending?: boolean;
    count?: number;
    timestamp_to?: number;
    timestamp_before?: number;
    page?: number;
}

export class LogsApi extends API {

    static toUsersFilter(filter: LogFilterModel): UsersFilter {
        return {
            timestamp_from: filter.range[0] && filter.range[0].startOf('day').unix(),
            timestamp_to: filter.range[1] && filter.range[1].endOf('day').unix(),
            channel_ids: filter.channels,
            version_ids: filter.versions,
            search: filter.text,
            with_fails: filter.isFails,
            // count: 5,
            page: filter.page,
            offset: filter.offset,
            ascending: false,
            count: filter.count
        };
    }

    static async getUsers(project: Project, filter?: LogFilterModel): Promise<ChatUser[]> {
        const results = await this.get<ChatUser[]>(
            `/projects/${project.id}/analytics/analytics-dialogs`,
            filter ? LogsApi.toUsersFilter(filter) : {ascending: false, count: 20, page: 1 }
        );

        results.forEach(user => {
            user.last_timestamp = user.last_timestamp * 1000;
            user.first_timestamp = user.first_timestamp * 1000;
        });

        return results;
    }

    static markEvent(project: Project, eventId: string): Promise<void> {
        return API.post(`/projects/${project.id}/analytics/events/${eventId}/mark`);
    }

    static async getEvents(project: Project, userId: string, filter?: LogChatPagination): Promise<Event[]> {
        const events = await this.get<Event[]>(
            `/projects/${project.id}/analytics/analytics-user-dialogs`,
            Object.assign({ user_id: userId }, filter)
        );

        events.forEach(event => event.timestamp = event.timestamp * 1000);


        return events;
    }
}
