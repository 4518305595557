import React, { FC, useEffect, useState } from 'react';
import { Button, Select, Radio, RadioChangeEvent, Space } from 'antd';
import { ConnectAPI } from '@/app/connects/connect.api';
import { PublishPageApi } from '@/app/publish/PublishPage.api';
import { Project } from '@/common/models/project';
import { Channel } from '@/app/connects/models/types';
import { PublishVersion } from '@/app/publish/PublishPage.model';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { CsvWrapper } from '@/app/stats/StatsPage.styled';
import { format } from 'date-fns';

type SearchKey = 'day' | 'week' | 'month';
export type Filter = {
    periodKey: 'day' | 'week' | 'month';
    period: number;
    channels?: string[];
    versions?: number[];
};

export type VersionsFilter = {
    periodKey: 'day' | 'week' | 'month';
    period: number;
};

export const AnalyticsFilter: FC<{
    project: Project;
    isLoading: boolean;
    search: (filter: Filter) => void,
    isEmpty: boolean,
    onDownload: () => void,
}> = ({project, search, isLoading, isEmpty, onDownload}) => {
    const [searchKey, setSearchKey] = useState<SearchKey>('day');
    const [period, setPeriod] = useState(7);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [versions, setVersions] = useState<PublishVersion[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<string[]>();
    const [selectedVersions, setSelectedVersions] = useState<number[]>();
    const handleChange = (value: SearchKey) => {
        setPeriod(buttons[value][0].value);
        setSearchKey(value);
    };

    useEffect(() => {
        Promise.all([ConnectAPI.getChannels(project), PublishPageApi.getVersions(project.id)])
            .then(([channels, versions]) => {
                setChannels(channels);
                const versionsWithDate = versions.map(version => {
                    return {...version, name: `${format(version.dateFrom, 'dd.MM.yy')} ${version.name}`}
                });

                setVersions(versionsWithDate);
            })
    }, []);

    const buttons: Record<SearchKey, { value: number; label: string }[]> = {
        'day': [{value: 7, label: '7 д.'}, {value: 30, label: '30 д.'}, {value: 60, label: '60 д.'}],
        'week': [{value: 4, label: '4 н.'}, {value: 8, label: '8 н.'}, {value: 12, label: '12 н.'}],
        'month': [{value: 3, label: '3 м.'}, {value: 6, label: '6 м.'}, {value: 12, label: '12 м.'}],
    };

    const onChangePeriod = (e: RadioChangeEvent) => {
        setPeriod(e.target.value);
    }

    const ok = () => {
        search({
            periodKey: searchKey,
            period,
            versions: selectedVersions,
            channels: selectedChannels
        });
    }

    const onChangeChannel = (value: number[]) => {
        const c: string[] = value.map(id => {
            const channel = channels.find(channel => channel.id === id);
            return channel.key;
        });

        setSelectedChannels(c);
    }

    const onChangeVersion = (value: number[]) => {
        setSelectedVersions(value);
    }


    return <Space>
        <Select
            defaultValue="day"
            style={{width: 160}}
            disabled={isLoading}
            onChange={handleChange}
            options={[
                {value: 'day', label: 'По дням'},
                {value: 'week', label: 'По неделям'},
                {value: 'month', label: 'По месяцам'},
            ]}
        />
        <Radio.Group
            options={buttons[searchKey]}
            onChange={onChangePeriod}
            value={period}
            disabled={isLoading}
            optionType="button"
            buttonStyle="solid"
        />

        <Select
            mode="multiple"
            maxTagCount={1}
            allowClear
            disabled={isLoading}
            onChange={onChangeChannel}
            style={{width: 220}}
            options={channels}
            placeholder={'Каналы'}
            fieldNames={{value: 'id', label: 'name'}}
        />

        <Select
            mode="multiple"
            maxTagCount={1}
            allowClear
            onChange={onChangeVersion}
            style={{width: 220}}
            options={versions}
            optionLabelProp={'name'}
            placeholder={'Версии'}
            disabled={isLoading}
            fieldNames={{value: 'id', label: 'name'}}
        />

        <Button
            type="primary"
            icon={<SearchOutlined/>}
            loading={isLoading}
            onClick={ok}
        >
            Ок
        </Button>

        {/*<Button*/}
        {/*    type="link"*/}
        {/*>*/}
        {/*    Настройки*/}
        {/*</Button>*/}

        {!isEmpty && <CsvWrapper>
            <Button onClick={onDownload} icon={<DownloadOutlined/>}> .csv </Button>
        </CsvWrapper>}
        {/*{buttons[searchKey].map(btn => <Button key={btn.value}>{ btn.label }</Button>)}*/}
    </Space>
}
