import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';
import { Channel } from '../../models/types';
import { Form, Input, Checkbox, Button, Space } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useEmail, useMaxLength, useRequired } from '@/common/utils/form-validators';

type HelpDeskEddyProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const HelpDeskEddy: FC<HelpDeskEddyProps> = observer(({
                                                                                             channel,
                                                                                             isManageChannels,
                                                                                             channelEditStore,
                                                                                             onFinish,
                                                                                             isRequest
                                                                                         }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const required = useRequired();
    const email = useEmail();
    const maxLength = useMaxLength(4096);

    const liaWebHook = `${getCurrentDomain()}/channels/helpdeskeddy?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const initialValues = {
        entrypoint: channel.params.entrypoint,
        email: channel.params.email,
        helpdeskeddy_token: channel.params.helpdeskeddy_token,
        unassign_on_terminate: channel.params.unassign_on_terminate ?? true,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.helpdeskeddy.input_label_link')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly />
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item name="entrypoint" rules={[required, maxLength]}
                   label={t('connect.helpdeskeddy.input_label_entrypoint')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, email, maxLength]} name="email" label={t('connect.helpdeskeddy.input_label_email')}>
            <Input type="email" disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="helpdeskeddy_token"
                   label={t('connect.helpdeskeddy.input_label_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item labelAlign="right" name="unassign_on_terminate"
                   valuePropName="checked"
                   label={t('connect.helpdeskeddy.mark_unassigned_on_terminate')}>
            <Checkbox disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
