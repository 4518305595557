import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { useTranslation } from 'react-i18next';
import { Button, Form, Space, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

type APIProps = {
    channel: Channel;
    onFinishForm: (values: any) => void;
    channelEditStore: ChannelEditStore;
}

export const API: FC<APIProps> = ({
                                                                      channel,
                                                                      channelEditStore,
                                                                      onFinishForm
                                                                  }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    useEffect(() => form.resetFields(), [channel.id]);

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}>
        <Form.Item label={t('connect.api.key')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={channel.api_key} readOnly/>
                <CopyToClipboard text={channel.api_key} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>
    </Form>
}

