import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/types';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Form, Input, InputNumber } from 'antd';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';

type InfobipProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}
//
// {
//     "channel_type": str,
//     "name": str,
//     "params":{
//     "chatwoot_api_key": str,
//         "chatwoot_base_url": str,
//         "max_message_length": int, (максимальная длина сообщения)
//     "long_text_intent_id": str (id интента который будет срабатывать при привышении max_message_length)
// }

export const Chatwoot: FC<InfobipProps> = observer(({
                                                                                   channel,
                                                                                   isManageChannels,
                                                                                   channelEditStore,
                                                                                   onFinish,
                                                                                   isRequest
                                                                               }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-');
    const maxLength128 = useMaxLength(128);

    const initialValues = {
        chatwoot_api_key: channel.params.chatwoot_api_key,
        chatwoot_base_url: channel.params.chatwoot_base_url,
        max_message_length: channel.params.max_message_length,
        long_text_intent_id: channel.params.long_text_intent_id,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item rules={[required, maxLength]} name="chatwoot_api_key"
                   label={t('connect.chatwoot.chatwoot_api_key')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="chatwoot_base_url" label={t('connect.chatwoot.chatwoot_base_url')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent_id"
                   label={t('connect.chatwoot.long_text_intent_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_length"
                   label={t('connect.chatwoot.max_message_length')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
