import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/types';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Space, Input } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { useMaxLength, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type LivechatProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Livechat: FC<LivechatProps> = observer(({
                                                                                     channel,
                                                                                     isManageChannels,
                                                                                     channelEditStore,
                                                                                     onFinish,
                                                                                     isRequest
                                                                                 }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const liaWebHook = `${getCurrentDomain()}/channels/livechat?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);

    const initialValues = {
        client_id: channel.params.client_id,
        client_secret: channel.params.client_secret,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.livechat.input_label_link')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="client_id"
                   label={t('connect.livechat.input_label_client_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="client_secret" label={t('connect.livechat.input_label_client_secret')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
