import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ConnectAPI } from './connect.api';
import { CooperationType } from './models/connect-type';
import { RootStore } from '@/core/stores/root.store';
import { Cooperation } from './models/types';
import { SaveButtonState } from '@/common/components/save-button/SaveButton';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';

export class CooperationStore {
    @observable isLoadCooperations: boolean = false;
    @observable cooperations: IObservableArray<Cooperation> = observable([]);

    @observable currentCooperation: Cooperation = null;
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'channels.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error',
    };

    channelEditStore = new ChannelEditStore();

    iconUrls: Record<string, string> = {};
    constructor(private root: RootStore) {
        makeObservable(this);
    }

    @action
    setCooperation(id: number, type_cooperation: string) {
        this.currentCooperation = this.cooperations.find(i => i.id === +id);

        if (!this.currentCooperation) {
            this.currentCooperation = this.cooperations.find(i => !i.id);

            if (!this.currentCooperation) {
                this.currentCooperation = this.addNewCooperation({
                    key: type_cooperation,
                    name: type_cooperation,
                    logo_url: '',
                    type: 'cooperation'
                })
            }
        }
    }

    get currentProject() {
        return this.root.projectStore.choosenProject;
    }

    @computed
    get firstChannel() {
        return this.cooperations[0];
    }

    @action.bound
    async loadCooperations(reload = false) {
        if (!reload && this.cooperations.length) return this.cooperations;
        const cooperations = await ConnectAPI.gerCooperations(this.currentProject);

        runInAction(() => {
            this.cooperations.replace(cooperations);
            this.isLoadCooperations = true;
        });

        return this.cooperations;
    }

    @action.bound
    async updateCooperation(cooperation: Cooperation) {
        const updatedCooperation = await ConnectAPI.updateCooperation(this.currentProject, cooperation);
        const _cooperation = this.cooperations.find(cooperationItem => cooperationItem.id === cooperation.id);
        Object.assign(_cooperation, updatedCooperation);
        return _cooperation;
    }

    @action.bound
    addNewCooperation(cooperation: CooperationType) {
        const newCooperation: Cooperation = {
            type_cooperation: cooperation.key,
            is_active: true,
            params: {
                api_key: ''
            }
        };

        this.cooperations.push(newCooperation);   

        return newCooperation;
    }

    @action.bound
    async createCooperation(cooperation: Cooperation) {
        const newCooperation = await ConnectAPI.createCooperation(this.currentProject, cooperation);
        const _cooperation = this.cooperations.find(cooperationItem => cooperationItem.id === cooperation.id);
        Object.assign(_cooperation, newCooperation);

        return newCooperation;
    }

    @action.bound
    async delete(cooperation: Cooperation) {
        await ConnectAPI.deleteCooperation(this.currentProject, cooperation);
        const deletedCooperation = this.cooperations.find(_channel => _channel.id === cooperation.id);

        if (deletedCooperation)
            this.cooperations.remove(deletedCooperation);
    }

    clearStore() {
        this.cooperations.replace([]);
    }
}
