import { API } from '@/common/api';
import { Project } from '../../common/models/project';
import { ConnectType, CooperationType } from './models/connect-type';
import { Channel, Cooperation } from './models/types';

export class ConnectAPI extends API {
    static getChannelTypes(): Promise<ConnectType[]> {
        return ConnectAPI.get<ConnectType[]>('/channel-types');
    }

    static getCooperationTypes(): Promise<CooperationType[]> {
        return Promise.resolve([
            {
                key: 'imotio',
                name: 'Imotio',
                logo_url: 'https://app.lia.chat/staticfiles/WEBP_AgADu1EAAo0FgUo.webp',
                type: 'cooperation'
            }
        ]);
    }

    static createNewChannel(project: Project, channel: Channel) {
        return ConnectAPI.post<Channel>(`/projects/${project.id}/channels`, channel);
    }

    static gerCooperations(project: Project) {
        return ConnectAPI.get<Cooperation[]>(`/projects/${project.id}/cooperations`);
    }

    static getChannels(project: Project) {
        return ConnectAPI.get<Channel[]>(`/projects/${project.id}/channels`);
    }

    static saveChannel(project: Project, channel: Channel) {
        return ConnectAPI.put<Channel>(`/projects/${project.id}/channels/${channel.id}`, channel);
    }

    static deleteChannel(project: Project, channel: Channel) {
        return ConnectAPI.delete(`/projects/${project.id}/channels/${channel.id}`);
    }

    static createCooperation(project: Project, cooperation: Cooperation) {
        return ConnectAPI.post<Cooperation>(`/projects/${project.id}/cooperations`, cooperation);
    }

    static deleteCooperation(project: Project, cooperation: Cooperation) {
        return ConnectAPI.delete<Cooperation>(`/projects/${project.id}/cooperations/${cooperation.id}`);
    }

    static updateCooperation(project: Project, cooperation: Cooperation) {
        return ConnectAPI.patch(`/projects/${project.id}/cooperations/${cooperation.id}`, cooperation);
    }
}
