import React, { FC, useEffect } from 'react';
import { Cooperation } from '../../models/types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { useRequired } from '@/common/utils/form-validators';

type TextbackProps = {
    cooperation: Cooperation;
    isManageChannels: boolean;
    channelEditStore: ChannelEditStore;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Imotio: FC<TextbackProps> = observer(({
                                                                                     cooperation,
                                                                                     isManageChannels,
                                                                                     channelEditStore,
                                                                                     onFinish,
                                                                                     isRequest
                                                                                 }) => {
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const required = useRequired();
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [cooperation.id]);

    const initialValues = {
        api_key: cooperation.params.api_key
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item name="api_key" rules={[required]}
                   label={'API ключ'}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
