import React, { FC, useEffect, useMemo } from 'react';
import { Route, Routes, useParams, useNavigate, useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { Button, Space, Switch } from 'antd';
import cn from './ConnectPageContent.module.scss';
import { EditableText } from '@/common/components/EditableText';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '../../chat/components/TestChat';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { UserStore } from '@/core/stores/user.store';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { DeleteOutlined } from '@ant-design/icons';
import { Imotio } from './cooperations/Imotio';
import { CooperationStore } from '../cooperation.store';


interface ConnectPageContentProps {
    cooperationStore?: CooperationStore;
    user?: UserStore;
}

export class ChannelEditStore {
    currentEditForm: FormInstance;

    setCurrentEditForm(form: FormInstance) {
        this.currentEditForm = form;
    }
}

export const CooperationsPage: FC<ConnectPageContentProps> = inject('user', 'cooperationStore')(observer(({user, cooperationStore}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const { id } = params;

    useEffect(() => {
        cooperationStore.setCooperation(+id, params['*']);
    }, [location]);

    const isRequest = useMemo(() => cooperationStore.saveState === SaveButtonState.process, []);

    const deleteChannel = async () => {
        Tracker.trackEvent('Edit', {Object: 'connect', Type: 'delete', ObjectId: cooperationStore.currentCooperation.id});
        const {projectId} = params;
        await cooperationStore.delete(cooperationStore.currentCooperation);
        navigate(`/app/${projectId}/connect`, { replace: true });
    };

    const onFinish = async (values: any) => {
        Tracker.trackEvent('Save', {Object: 'connect', ObjectId: cooperationStore.currentCooperation.id});
        const currentCooperation = Object.assign(cooperationStore.currentCooperation, {params: values});
        cooperationStore.saveState = SaveButtonState.process;
        console.log(cooperationStore.currentCooperation);

        try {
            const cooperation = cooperationStore.currentCooperation.id ? await cooperationStore.updateCooperation(currentCooperation) : await cooperationStore.createCooperation(currentCooperation);

            cooperationStore.saveState = SaveButtonState.saved;

            setTimeout(() => {
                cooperationStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);

            const {projectId} = params;

            navigate(`/app/${projectId}/connect/cooperations/${cooperation.id}/${cooperation.type_cooperation}`, { replace: true });
        } catch (e) {
            cooperationStore.saveState = SaveButtonState.error;
            setTimeout(() => {
                cooperationStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    }


    const submit = () => {
        cooperationStore.channelEditStore.currentEditForm?.submit();
    };

    if (!cooperationStore.currentCooperation) {
        return null;
    }

    return <Page actionMenu={
        user.permissions.isManageChannels && <ActionsMenu
            right={
                <Space size={[12, 0]} className="btn-group-sm" id="connect-page-right-actions">
                    <div className={cn.leftActionsMenu} id="disabledActivatePopover"
                        
                    >
                        <Space size={[4, 0]} wrap>
                            {cooperationStore.currentCooperation.is_active ? 'Включен' : 'Выключен'}
                            <Switch size={'small'} checked={cooperationStore.currentCooperation.is_active}
                                    onChange={checked => cooperationStore.currentCooperation.is_active = checked}
                                    disabled={!cooperationStore.currentCooperation.id}
                            />
                        </Space>
                    </div>
                    <ConfirmDelete title={t('actions.delete_channel')}
                                   question={t('actions.delete_element', {name: cooperationStore.currentCooperation.name})}
                                   onConfirm={() => deleteChannel()}>
                        <Button title="Удалить" icon={<DeleteOutlined/>}/>
                    </ConfirmDelete>
                    <SaveButton onClick={submit}
                                state={cooperationStore.saveState}
                                titlesByState={cooperationStore.titlesByState}/>
                </Space>
            }
        />
    } rightBar={
        <TestChat/>
    }>
        <div className={cn.header}>
            <EditableText className={cn.entityTitle} text={cooperationStore.currentCooperation.type_cooperation}
                          onEdit={value => cooperationStore.currentCooperation.name = value}
                          editable={false}/>
        </div>
        <div className={cn.pageConent}>
            <Routes>
                <Route path={'imotio'}
                       element={<Imotio channelEditStore={cooperationStore.channelEditStore}
                       onFinish={onFinish}
                       isRequest={isRequest}
                       isManageChannels={user.permissions.isManageChannels}
                       cooperation={cooperationStore.currentCooperation}/>}/>

            </Routes>
        </div>
    </Page>
}));
