import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input, InputNumber } from 'antd';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { useRequired } from '@/common/utils/form-validators';

type TextbackProps = {
    channel: Channel;
    isManageChannels: boolean;
    channelEditStore: ChannelEditStore;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Textback: FC<TextbackProps> = observer(({
                                                                                     channel,
                                                                                     isManageChannels,
                                                                                     channelEditStore,
                                                                                     onFinish,
                                                                                     isRequest
                                                                                 }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const required = useRequired();
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const initialValues = {
        textback_token: channel.params.textback_token,
        mark_unread_on_terminate: channel.params.mark_unread_on_terminate ?? true,
        silence_time_after_terminate: channel.params.silence_time_after_terminate ?? 14400,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item name="textback_token" rules={[required]}
                   label={t('connect.textback.textback_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="silence_time_after_terminate"
                   label={t('connect.textback.silence_time_after_terminate')}>
            <InputNumber disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item labelAlign="right" valuePropName="checked" name="mark_unread_on_terminate"
                   label={t('connect.textback.mark_unread_on_terminate')}>
            <Checkbox disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
